export interface CardProps {
  image: { url: string; alt?: string };
  name: string;
  return: string;
  term: string;
  ltv: string;
  investors: number;
  percentFunded: number;
  match?: 'PASS' | 'MATCH';
}

export const cardData: CardProps[] = [
  {
    image: { url: '/images/horses/horse-1.jpeg' },
    name: 'Prince',
    investors: 9,
    ltv: '10',
    return: '10',
    percentFunded: 41,
    term: '10',
    match: 'PASS',
  },
  {
    image: { url: '/images/horses/horse-2.jpeg' },
    name: 'Emmy',
    investors: 26,
    ltv: '10',
    return: '10',
    percentFunded: 87,
    term: '10',
    match: 'PASS',
  },
  {
    image: { url: '/images/horses/horse-3.jpeg' },
    name: 'Ava',
    investors: 14,
    ltv: '10',
    return: '10',
    percentFunded: 63,
    term: '10',
    match: 'MATCH',
  },
];

export const cardData2: CardProps[] = [
  {
    image: { url: '/images/horses/horse-5.jpeg' },
    name: 'Parzival',
    investors: 2,
    ltv: '10',
    return: '10',
    percentFunded: 19,
    term: '5',
  },
  {
    image: { url: '/images/horses/horse-6.png' },
    name: 'Charisma',
    investors: 31,
    ltv: '10',
    return: '10',
    percentFunded: 95,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-7.jpeg' },
    name: 'Cody',
    investors: 20,
    ltv: '10',
    return: '10',
    percentFunded: 83,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-8.jpeg' },
    name: 'Buck',
    investors: 14,
    ltv: '10',
    return: '10',
    percentFunded: 63,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-1.jpeg' },
    name: 'Prince',
    investors: 9,
    ltv: '10',
    return: '10',
    percentFunded: 41,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-2.jpeg' },
    name: 'Emmy',
    investors: 26,
    ltv: '10',
    return: '10',
    percentFunded: 87,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-3.jpeg' },
    name: 'Ava',
    investors: 14,
    ltv: '10',
    return: '10',
    percentFunded: 63,
    term: '10',
  },
  {
    image: { url: '/images/horses/horse-4.jpeg' },
    name: 'Ava',
    investors: 14,
    ltv: '10',
    return: '10',
    percentFunded: 63,
    term: '10',
  },
];
