import { theme } from './theme';

// used in styled components like: ${smallest} { ...css}

const smallest = `@media (max-width: ${theme.breakpoints.sm})`;
const phone = `@media (max-width: ${theme.breakpoints.md})`;
const tablet = `@media (max-width: ${theme.breakpoints.lg})`;
const laptop = `@media (max-width: ${theme.breakpoints.xl})`;
const desktop = `@media (max-width: ${theme.breakpoints.xxl})`;

const minSmallest = `@media (min-width: ${theme.breakpoints.sm})`;
const minPhone = `@media (min-width: ${theme.breakpoints.md})`;
const minTablet = `@media (min-width: ${theme.breakpoints.lg})`;
const minLaptop = `@media (min-width: ${theme.breakpoints.xl})`;
const minDesktop = `@media (min-width: ${theme.breakpoints.xxl})`;

const devices = {
  laptop,
  tablet,
  phone,
  smallest,
  desktop,
};

export {
  devices,
  desktop,
  laptop,
  tablet,
  phone,
  smallest,
  minLaptop,
  minTablet,
  minPhone,
  minSmallest,
  minDesktop,
};
