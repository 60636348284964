import { SVGProps } from 'react';
import { FaHorseHead as Logo } from 'react-icons/fa';

export { Logo };

export const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 42 42"
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20.678} r={19.5} stroke="#000" />
    <path
      d="M26.279 21.031a.5.5 0 0 0 0-.707l-3.182-3.182a.5.5 0 1 0-.707.707l2.828 2.829-2.828 2.828a.5.5 0 1 0 .707.707l3.182-3.182Zm-12.947.147h12.593v-1H13.332v1Z"
      fill="#000"
    />
  </svg>
);

export const Medal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 2900 2000"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m2440.77 166.291 35.53 126.329-73.61 217.07-111.68-343.399h149.76Z"
      style={{
        fill: '#cd3414',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="m2418.35 166.291 44.33 166.497 5.74-16.933-39.82-149.564h-10.25Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2341.77 562.993v-73.608c0-9.813 7.96-17.768 17.77-17.768h200.52c9.81 0 17.77 7.955 17.77 17.768v76.146h-40.61v-38.073c0-9.813-7.96-17.768-17.77-17.768h-116.76c-9.81 0-17.77 7.955-17.77 17.768v25.382l-43.15 10.153Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="m2412.28 481.413 5.28-15.57-97.23-299.552h-10.34l102.29 315.122Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2250.4 733.054c0-114.95 93.18-208.134 208.13-208.134 114.95 0 208.14 93.184 208.14 208.134 0 114.949-93.19 208.134-208.14 208.134-114.95 0-208.13-93.185-208.13-208.134Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2250.4 733.054c0 11.326.92 22.437 2.66 33.273 30.22 17.094 65.13 26.862 102.32 26.862 114.95 0 208.14-93.185 208.14-208.134 0-11.327-.92-22.438-2.66-33.273-30.22-17.095-65.13-26.862-102.33-26.862-114.95 0-208.13 93.184-208.13 208.134Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2402.69 509.69h129.45l147.22-345.178h-162.45L2402.69 509.69Z"
      style={{
        fill: '#fe5629',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2458.53 587.296c-80.37 0-145.75 65.387-145.75 145.758 0 80.37 65.38 145.757 145.75 145.757s145.76-65.387 145.76-145.757c0-80.371-65.39-145.758-145.76-145.758Zm0 296.591c-83.17 0-150.83-67.663-150.83-150.833s67.66-150.834 150.83-150.834c83.17 0 150.84 67.664 150.84 150.834s-67.67 150.833-150.84 150.833Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2297.36 649.292s-16.5-34.265 19.03-50.764c0 0 2.54 35.535-19.03 50.764Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2298.63 663.253s-2.54-25.382 38.07-31.728c0 0-6.35 24.113-38.07 31.728ZM2326.92 612.202s-9.02-36.947 29.17-45.698c0 0-4.91 35.286-29.17 45.698Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2325.25 626.121s2.8-25.356 43.84-23.12c0 0-11.22 22.267-43.84 23.12ZM2361.39 582.544s-1.85-37.986 37.31-39.342c0 0-11.51 33.718-37.31 39.342Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2357.12 595.896s7.55-24.367 47.43-14.394c0 0-15.24 19.738-47.43 14.394ZM2400.09 559.887s9.5-36.826 47.29-26.509c0 0-20.98 28.79-47.29 26.509Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2392.05 571.372s14.44-21.031 49.56.319c0 0-20.4 14.331-49.56-.319ZM2444.91 550.794s20.2-32.222 53.1-10.948c0 0-28.71 21.087-53.1 10.948Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2433.77 559.309s20.13-15.676 47.14 15.305c0 0-23.78 7.482-47.14-15.305ZM2491.92 554.504s28.54-25.133 54.02 4.631c0 0-33.53 12.034-54.02-4.631Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2478.82 559.494s23.75-9.294 40.82 28.098c0 0-24.93.398-40.82-28.098ZM2535.61 572.958s33.14-18.651 51.88 15.759c0 0-35.31 4.8-51.88-15.759Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2521.76 575.115s25.17-4.152 34.09 35.971c0 0-24.47-4.794-34.09-35.971ZM2573.47 598.152s36.08-12.032 47.95 25.304c0 0-35.57-1.976-47.95-25.304Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2559.46 597.645s25.5.693 26.66 41.78c0 0-23.12-9.344-26.66-41.78ZM2605.73 629.297s38.02-.792 38.29 38.385c0 0-33.39-12.436-38.29-38.385Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2592.5 624.658s24.15 8.224 13.07 47.807c0 0-19.31-15.78-13.07-47.807ZM2626.58 670.004s36.48 10.755 24.87 48.175c0 0-28.05-21.959-24.87-48.175Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2615.38 661.579s20.52 15.147-2.02 49.519c0 0-13.62-20.883 2.02-49.519ZM2638.95 713.541s32.26 20.128 11.06 53.075c0 0-21.15-28.667-11.06-53.075Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2630.41 702.425s15.72 20.088-15.2 47.172c0 0-7.54-23.768 15.2-47.172ZM2633.81 760.691s27.38 26.396-.21 54.216c0 0-14.73-32.438.21-54.216Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2627.77 748.042s11.2 22.918-24.68 42.982c0 0-2.43-24.815 24.68-42.982ZM2620.54 804.191s21.88 31.106-10.47 53.194c0 0-8.32-34.641 10.47-53.194Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2617.01 790.627s6.65 24.625-32.37 37.527c0 0 2.31-24.826 32.37-37.527ZM2599.97 844.032s11.67 36.195-25.78 47.694c0 0 2.33-35.549 25.78-47.694Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2600.61 830.029s-.94 25.491-42.04 26.239c0 0 9.57-23.022 42.04-26.239ZM2566.96 875.689s.16 38.031-39.01 37.653c0 0 12.98-33.176 39.01-37.653Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2571.81 862.539s-8.62 24.009-48.01 12.282c0 0 16.09-19.045 48.01-12.282ZM2533.82 900.373s-8.54 37.058-46.59 27.717c0 0 20.24-29.32 46.59-27.717Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2541.56 888.685s-13.89 21.396-49.55.957c0 0 20.03-14.852 49.55-.957ZM2487.57 910.839s-16.07 34.472-51.34 17.425c0 0 25.88-24.473 51.34-17.425Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2497.56 901.015s-18.03 18.042-48.66-9.364c0 0 22.67-10.364 48.66 9.364ZM2442.11 912.431s-22.3 30.803-53.71 7.38c0 0 30.06-19.123 53.71-7.38Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2453.79 904.679s-21.12 14.297-46.01-18.417c0 0 24.23-5.879 46.01 18.417ZM2397.75 905.921s-30.44 22.802-53.48-8.881c0 0 34.37-9.349 53.48 8.881Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2411.2 901.981s-24.41 7.389-38.48-31.234c0 0 24.89 1.572 38.48 31.234ZM2357.08 884.992s-35.91 12.519-48.28-24.654c0 0 35.59 1.496 48.28 24.654Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2371.1 885.31s-25.51-.348-27.22-41.417c0 0 23.24 9.031 27.22 41.417ZM2325.02 859.227s-37.87 3.53-40.96-35.526c0 0 34.2 9.997 40.96 35.526Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2338.55 862.899s-24.68-6.461-16.48-46.74c0 0 20.39 14.347 16.48 46.74ZM2300.55 818.599s-37.78-4.418-32.68-43.263c0 0 31.37 16.888 32.68 43.263Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2313.02 825.004s-22.8-11.45-6.41-49.145c0 0 16.97 18.273 6.41 49.145ZM2284.75 775.952s-36.25-11.497-23.88-48.672c0 0 27.6 22.526 23.88 48.672Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2295.78 784.603s-20.21-15.562 3.03-49.468c0 0 13.19 21.157-3.03 49.468ZM2276.99 731.789s-31.21-21.731-8.37-53.566c0 0 19.68 29.698 8.37 53.566Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2284.96 743.322s-14.69-20.856 17.56-46.345c0 0 6.33 24.118-17.56 46.345ZM2284.08 686.605s-23.17-30.159 8.23-53.589c0 0 9.77 34.262-8.23 53.589Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2288.18 700.008s-7.68-24.323 30.77-38.855c0 0-1.27 24.902-30.77 38.855Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
    <path
      d="M2416.96 509.69h10.39l123.78-345.178h-10.39L2416.96 509.69ZM2506.46 509.69h10.5l144.51-345.178h-10.5L2506.46 509.69Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(-2839.13 50.273) scale(1.7411)"
    />
  </svg>
);

export const Award = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 2900 2000"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m1178.78 637.539 79.79 267.868 65.54-94.039 108.29 39.896-85.49-259.319-168.13 45.594Z"
      style={{
        fill: '#cd3414',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1269.86 889.212 5.45-7.819 1.72 5.633-7.17 2.186Z"
      style={{
        fill: '#ffdd7d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1268.61 889.593-39.63-129.878 5.08-13.498 41.25 135.176-5.45 7.819-1.25.381Z"
      style={{
        fill: '#f09f36',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1402.56 844.036-1.35-4.262 6.86 2.526-5.51 1.736Z"
      style={{
        fill: '#ffdd7d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1408.07 842.3-6.86-2.526-64.2-203.763 8.4-2.643 65.54 208.025-2.88.907Z"
      style={{
        fill: '#f09f36',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1081.89 609.042-85.489 247.921 116.839-39.896 59.84 91.19 99.74-265.019-190.93-34.196Z"
      style={{
        fill: '#fe5629',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1031.93 846.927-3.14-1.023 3.91-1.335-.77 2.358Zm75.94-233.232-8.75-1.566 4.24-12.998 8.36 2.724-3.85 11.84Z"
      style={{
        fill: '#ffdd7d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1028.79 845.904-5.23-1.701 75.56-232.074 8.75 1.566-75.17 230.874-3.91 1.335Z"
      style={{
        fill: '#ffaa3d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1160.34 888.838-5.66-1.988 1.52-4.316 4.14 6.304Z"
      style={{
        fill: '#ffdd7d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1251.04 639.339-8.77-1.572 3.6-10.236 8.3 2.918-3.13 8.89Z"
      style={{
        fill: '#f09f36',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1162.98 889.768-2.64-.93-4.14-6.304 86.07-244.767 8.77 1.572-88.06 250.429Z"
      style={{
        fill: '#ffaa3d',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M956.505 435.213c0-11.981 29.204-21.754 30.847-33.281 1.684-11.814-23.578-29.379-20.29-40.595 3.345-11.411 34.118-12.546 38.998-23.21 4.93-10.783-14.323-34.791-7.957-44.677 6.407-9.946 36.247-2.354 43.967-11.257 7.73-8.919-4-37.38 4.92-45.115 8.9-7.721 35.41 7.927 45.35 1.522 9.89-6.366 6.61-36.985 17.4-41.917 10.66-4.878 31.66 17.608 43.08 14.263 11.21-3.288 16.74-33.583 28.55-35.267 11.53-1.643 25.32 25.862 37.3 25.862 11.99 0 25.78-27.505 37.31-25.862 11.81 1.684 17.34 31.979 28.55 35.267 11.41 3.346 32.42-19.141 43.08-14.263 10.79 4.932 7.51 35.551 17.4 41.918 9.94 6.404 36.45-9.243 45.35-1.522 8.92 7.735-2.81 36.196 4.92 45.115 7.72 8.903 37.56 1.311 43.97 11.257 6.36 9.886-12.89 33.894-7.96 44.677 4.88 10.664 35.65 11.799 39 23.21 3.28 11.216-21.98 28.78-20.29 40.594 1.64 11.528 30.84 21.3 30.84 33.281 0 11.981-29.2 21.753-30.84 33.281-1.69 11.814 23.57 29.378 20.29 40.595-3.35 11.411-34.12 12.546-39 23.21-4.93 10.782 14.32 34.791 7.96 44.677-6.41 9.946-36.25 2.354-43.97 11.257-7.74 8.919 4 37.38-4.92 45.115-8.9 7.72-35.41-7.927-45.35-1.522-9.89 6.366-6.61 36.985-17.4 41.917-10.66 4.878-31.67-17.608-43.08-14.263-11.21 3.288-16.74 33.583-28.55 35.267-11.53 1.643-25.32-25.862-37.31-25.862-11.98 0-25.77 27.505-37.3 25.862-11.81-1.684-17.34-31.979-28.55-35.267-11.42-3.346-32.42 19.14-43.09 14.263-10.78-4.933-7.5-35.551-17.39-41.918-9.95-6.404-36.45 9.243-45.36 1.522-8.92-7.735 2.82-36.196-4.91-45.115-7.72-8.903-37.56-1.312-43.968-11.257-6.366-9.886 12.888-33.894 7.958-44.677-4.88-10.664-35.653-11.799-38.998-23.21-3.288-11.216 21.974-28.78 20.29-40.595-1.643-11.527-30.847-21.299-30.847-33.28Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M1007.8 435.213c0-116.463 94.41-210.875 210.87-210.875 116.47 0 210.88 94.412 210.88 210.875s-94.41 210.875-210.88 210.875c-116.46 0-210.87-94.412-210.87-210.875Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M1218.67 228.612c-113.92 0-206.6 92.681-206.6 206.601s92.68 206.6 206.6 206.6c113.92 0 206.6-92.68 206.6-206.6 0-113.92-92.68-206.601-206.6-206.601Zm0 421.75c-57.46 0-111.49-22.379-152.13-63.015-40.64-40.637-63.02-94.666-63.02-152.134 0-57.468 22.38-111.497 63.02-152.134 40.64-40.636 94.67-63.015 152.13-63.015 57.47 0 111.5 22.379 152.14 63.015 40.63 40.637 63.01 94.666 63.01 152.134 0 57.468-22.38 111.497-63.01 152.134-40.64 40.636-94.67 63.015-152.14 63.015Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M1067.64 435.213c0-83.413 67.62-151.032 151.03-151.032 83.42 0 151.04 67.619 151.04 151.032 0 83.413-67.62 151.032-151.04 151.032-83.41 0-151.03-67.619-151.03-151.032Z"
      style={{
        fill: '#ffd733',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M1344.06 375.373c0-9.618-.94-19.012-2.66-28.131-27.41-38.172-72.15-63.061-122.73-63.061-83.41 0-151.03 67.619-151.03 151.032 0 9.618.94 19.012 2.66 28.13 27.41 38.173 72.15 63.062 122.73 63.062 83.41 0 151.03-67.619 151.03-151.032Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="M1218.67 288.58c-80.85 0-146.63 65.779-146.63 146.633 0 80.854 65.78 146.633 146.63 146.633 80.86 0 146.64-65.779 146.64-146.633 0-80.854-65.78-146.633-146.64-146.633Zm0 302.064c-85.7 0-155.43-69.726-155.43-155.431 0-85.705 69.73-155.431 155.43-155.431 85.71 0 155.44 69.726 155.44 155.431 0 85.705-69.73 155.431-155.44 155.431Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
    <path
      d="m1114.32 309.586 13.31-33.613 12.45 34.079 23.32 13.306-23.79 12.455-13.3 42.743-12.46-43.209-23.31-13.306 23.78-12.455Z"
      style={{
        fill: '#fff',
        fillRule: 'nonzero',
      }}
      transform="translate(-1220.77 -201.143) scale(2.2301)"
    />
  </svg>
);

export const Horse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 1875 1875"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m60.819 126.697-2.716.772v.802s2.566-.63 3.13-.928c.564-.298.626-.296.626-.296l-1.04-.35Z"
      style={{
        fill: '#3f2b2b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M35.761 119.727s7.113-2.136 12.747 0c7.025 2.664 10.093 8.249 11.787 10.669 1.693 2.419-11.795-4.688-11.795-4.688l-11.212-3.35-1.527-2.631Z"
      style={{
        fill: '#684840',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M105.147 140.461s3.976-3.018 7.344-.631c2.226 1.577 2.817 5.113 2.616 12.731-.201 7.618 6.036 13.554 11.469 10.435-1.61-.906-2.113-3.018-2.113-3.018s3.219.503 4.93-1.912c1.547-2.186-.203-4.325-.203-4.325s-1.91 4.225-4.828 1.097c-.834-.894-.402-7.752-1.408-13.58-1.006-5.827-4.966-8.721-11.469-7.205-5.047 1.176-7.646 4.698-7.646 4.698l1.308 1.71Z"
      style={{
        fill: '#77534d',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M105.986 175.974a7.96 7.96 0 0 0 2.388 2.171c-.304-2.796-.882-7.615-1.936-11.233-.657-2.255-3.52-5.611-3.063-7.325a1312.368 1312.368 0 0 0 3.063-11.718l-19.961 3.138s4.418 10.135 7.101 12.452c2.682 2.317 7.512 4.579 8.317 5.905.274.453.613 1.804.918 3.42.885.689 2.138 1.791 3.173 3.19Z"
      style={{
        fill: '#896f5d',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M103.504 180.548c-2.414 3.641-2.414 5.814-2.414 5.814l4.695.041s-.418-2.693.941-3.18c1.358-.488 2.144-1.078 1.875-3.007-.046-.335-.12-1.078-.227-2.071a7.96 7.96 0 0 1-2.388-2.171c-1.035-1.399-2.288-2.501-3.173-3.19.589 3.116 1.054 7.217.691 7.764Z"
      style={{
        fill: '#e4d6d6',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="m63.035 154.838-5.404-3.716s-1.067 8.664-3.465 11.208c-1.977 2.096-2.457 6.42-3.412 9.995.255.705.47 1.656.47 2.909 0 1.056.423 1.962.87 2.635.945-2.866 2.563-6.963 3.161-8.505.805-2.076 7.78-14.526 7.78-14.526Z"
      style={{
        fill: '#896f5d',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M50.754 172.325a19.145 19.145 0 0 1-.693 2.16c-1.514 3.786-4.047 6.374-5.275 7.324-1.692 1.307-3.051 4.594-3.051 4.594h5.062s.091-2.641 1.7-2.976c1.61-.335 2.628-1.475 2.928-3.181.095-.539.343-1.386.669-2.377-.447-.673-.87-1.579-.87-2.635 0-1.253-.215-2.204-.47-2.909Z"
      style={{
        fill: '#e4d6d6',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M33.896 121.749s-.529-1.861.678-4.024c1.208-2.163 1.811-2.565 1.811-2.565s1.193 2.263.32 4.275c-.873 2.012-1.6 2.854-1.6 2.854l-1.209-.54Z"
      style={{
        fill: '#5b3e39',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M98.406 132.621c-6.237-2.96-13.491.53-19.047 1.29-4.353.596-13.593-.845-17.901-2.845-3.75-1.741-7.6-7.754-13.182-9.986-2.003-.802-6.329-1.029-10.136-.333-3.448.63-6.492 2.071-7.076 4.723 0 0-.051.092-.128.252a7.008 7.008 0 0 1 1.211.028s-.181 2.785-.294 4.389c-.112 1.603 1.367 6.305 1.497 8.875.075 1.471-.741 1.72-1.365 2.389.077.613.249 1.977.309 2.333.351 2.107 3.165 1.851 3.824 1.324.567.454 1.554.366 2.081-.336.527-.703.769-1.691.769-3.096s1.169-2.287 1.866-5.269c.377-1.614.768-3.007.768-4.05 1.405 1.036 3.09 4.345 3.337 5.685.581 3.143.915 5.083 1.756 8.029 1.581 5.528 4.391 7.548 4.391 7.548 4.567 3.02 14.402 4.903 21.778 5.178 7.377.274 12.458-.951 15.632-2.158 2.81-1.069 9.659 1.889 12.821 1.019 3.162-.87 4.6-3.935 7.326-10.784 2.161-5.432-3.286-10.906-10.237-14.205Z"
      style={{
        fill: '#b39885',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M31.853 130.139c.113-1.604.294-4.389.294-4.389s-.533-.07-1.211-.028c-.236.491-.713 1.638-.713 2.859 0 1.619.25 1.56.668 3.752.274 1.435.716 6.053 1.094 9.07.624-.669 1.44-.918 1.365-2.389-.13-2.57-1.609-7.272-1.497-8.875ZM41.602 132.309l.266-1.913s3.245 2.003 4.567 5.964c1.321 3.96 2.968 10.47 4.526 12.673 2.112 2.983 5.349 3.037 5.349 3.037l-.142 3.736s-2.374-.514-4.734-2.006c-2.36-1.493-3.577-3.794-5.18-9.406-1.509-5.287-1.578-9.088-4.652-12.085ZM59.59 156.875l.588-3.134s9.487 2.252 16.009 1.376c9.745-1.309 13.764-3.995 13.764-3.995l-1.939 5.689s-2.305.732-4.669 1.22c-2.086.431-4.186.623-5.226.657-11.097.363-18.527-1.813-18.527-1.813Z"
      style={{
        fill: '#9e8372',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M52.494 149.701c-6.368 7.738-11.327 10.755-14.01 12.385-2.682 1.63-1.878 3.958-1.341 5.075.138.286.454.792.863 1.42 1.359-.013 3.105.114 4.819.635l-1.121-2.055s14.13-4.973 15.421-6.672c1.291-1.698 4.333-6.523 4.333-6.523s-7.388-6.181-8.964-4.265Z"
      style={{
        fill: '#b39885',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M38.006 168.581c1.186 1.82 3.228 4.656 3.966 6.332 1.707 3.878 5.284 5.333 5.284 5.333l.35-5.333s-1.613-.5-2.683-1.848c-.681-.858-2.098-3.849-2.098-3.849-1.714-.521-3.46-.648-4.819-.635Z"
      style={{
        fill: '#dbc7c7',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M90.09 146.826s-2.666 13.219-3.613 16.707c-.948 3.487-2.826 7.588-4.167 9.427-.193.266-.405.572-.626.901.44.514 1.044 1.457 1.498 3.085.107.383.222.719.341 1.02.789-1.79 1.809-3.612 2.954-4.365 2.808-1.849 3.881-2.828 4.686-4.839.805-2.01 1.609-6.388 4.561-7.15 2.951-.762 8.92-3.36 11.138-10.49 1.573-5.06-16.772-4.296-16.772-4.296Z"
      style={{
        fill: '#b39885',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M81.684 173.861c-1.313 1.956-3.016 4.813-3.934 5.872-1.074 1.237-4.293 6.629-4.293 6.629h4.829s.539-2.301 1.61-2.388c1.475-.12 1.743-.929 2.012-1.662.141-.387.734-2.347 1.615-4.346a10.294 10.294 0 0 1-.341-1.02c-.454-1.628-1.058-2.571-1.498-3.085Z"
      style={{
        fill: '#e4d6d6',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M32.838 132.224s-.325-.882.297-1.411c.794-.674 1.788-.674 1.788-.674s.391 1.139-.273 1.719c-.664.58-1.812.366-1.812.366Z"
      style={{
        fill: '#77534d',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M30.547 126.697s.166-2.69 1.306-4.143c.815-1.038 2.213-1.681 2.898-1.821 1.847-.378 2.422-.191 2.422-.191l.902 6.82-7.528-.665Z"
      style={{
        fill: '#684840',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M32.007 141.794s2.088-.566 3.522-.566c1.434 0 3.446.329 3.446.329l.23-1.089s-1.951-.409-4.193-.327c-1.628.06-3.159.626-3.159.626l.154 1.027ZM30.223 128.581s.713.311 4.167-1.131c1.455-.608 3.685-.088 3.685-.088v-2.025s-7.033-.971-7.441-.335c-1.107 1.723-.411 3.579-.411 3.579Z"
      style={{
        fill: '#3f2b2b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M37.295 139.563a.984.984 0 1 0 0 1.969.984.984 0 0 0 0-1.969Zm0 2.494a1.513 1.513 0 0 1-1.511-1.511 1.512 1.512 0 0 1 3.022 0c0 .833-.678 1.511-1.511 1.511Z"
      style={{
        fill: '#fff',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M37.288 122.358c.057 1.621.341 3.827.332 6.633-.012 3.013-.915 10.854-.915 10.854h1.165s.699-6.826 1.028-10.324c.284-3.017-.56-7.459-1.61-8.414-.124.593 0 1.251 0 1.251ZM62.06 127.133c-1.52 1.338-3.442 2.932-5.165 4.067-6.577 4.332-18.71 8.93-18.71 8.93l.087.571s11.543-3.761 18.623-8.189c2.182-1.365 4.232-3.041 5.875-4.522a7.372 7.372 0 0 0-.71-.857Z"
      style={{
        fill: '#3f2b2b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M35.038 123.102s.113-3.389 1.56-5.423c1.447-2.033 2.208-2.167 2.208-2.167s.177 6.165-1.257 7.874c-1.765 1.4-2.511-.284-2.511-.284Z"
      style={{
        fill: '#77534d',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M42.155 167.988s-2.298.512-1.595 2.151c.722 1.68 1.175 2.364 2.817 4.485 1.15 1.486 3.148-.211 3.148-.211s-1.236-1.558-2.243-3.268c-1.006-1.71-2.127-3.157-2.127-3.157Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="m41.365 169.988-1.741 1.01.408.613 1.703-1.07-.37-.553ZM43.446 173.601l-1.651.958.318.666 1.703-1.07-.37-.554ZM53.565 173.755s-1.905-.636-2.811 1.479c-.905 2.115-2.078 3.885-1.726 5.012.351 1.127 1.726 1.715 1.726 1.715s1.34-3.332 2.811-8.206Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="m49.741 175.234 1.55.74.369-.749-1.599-.74-.32.749ZM80.957 174.97s1.062 1.194 0 3.53c-1.061 2.336-3.207 1.233-3.207 1.233l3.207-4.763ZM107.879 174.127s-1.893 1.773-1.625 3.839c.268 2.065 2.347 2.25 2.347 2.25l-.722-6.089Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M65.435 131.943s-.887-4.063 1.258-3.334c2.145.729 6.304.68 9.925 0 3.622-.68 4.197-.532 4.158 2.289-.03 2.206-1.207 3.629-1.207 3.629l-12.876-1-1.258-1.584Z"
      style={{
        fill: '#5b3a39',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M70.029 147.629s-.255 4.412-1.029 6.871c-.774 2.46-1.98 3.752-1.98 3.752s3.914.418 5.23.485c1.14.058 4.594.015 4.594.015s-.124-.872-1.006-1.524c-.607-.449-2.231-.611-2.566-1.067-1.018-1.386.185-8.292.185-8.292l-3.428-.24Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M60.295 130.396s-.796 5.124.147 11.518c.943 6.394 6.577 8.003 13.015 8.104 6.439.101 6.494.135 8.421.034 1.928-.1 3.618-.719 3.651-4.719.028-3.269.005-10.611-.603-12.719-1.229-1.536-2.153-1.62-3.692-1.413-1.54.206-8.783 1.039-12.203.522-3.421-.517-8.736-1.327-8.736-1.327Z"
      style={{
        fill: '#3f2b2b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M65.253 131.15s-1.327 2.504-1.856 5.354c-.452 2.434.167 5.553 1.856 6.949 1.476 1.219 5.225 1.938 7.218 1.636 1.992-.302 2.205-2.473 2.3-4.959.094-2.486 2.073-8.473 2.073-8.473s-8.115.085-11.591-.507Z"
      style={{
        fill: '#5b3a39',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M81.438 143.453c-.583 0-2.126.462-2.03 1.419.097.978.535 1.645 1.017 2.298.774 1.049 1.503.808 2.034 0 .45-.686.92-1.32 1.017-2.298.096-.957-1.453-1.419-2.038-1.419Z"
      style={{
        fill: '#cb603b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M81.439 144.005c.281.326-1.57.341-1.5 1.047.073.723.396 1.216.752 1.698.572.774 1.11.596 1.502 0 .332-.507.679-.975.751-1.698.071-.706-4.328-4.318-1.505-1.047Z"
      style={{
        fill: '#fff',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M79.814 149.351c-.404 0-.811-.01-1.207-.019a48.813 48.813 0 0 0-2.043-.008c-2.23.034-5.955.087-9.152-.899-3.767-1.161-5.065-2.981-6.143-5.933-1.086-2.974-.451-11.63-.423-11.997l.402.03c-.007.089-.658 8.935.399 11.829 1.057 2.895 2.257 4.569 5.884 5.687 3.137.967 6.821.916 9.028.88.8-.011 1.373-.008 2.058.008 1.837.043 3.916.094 4.713-.852.935-1.108.997-2.973.925-4.825-.063-1.604-.255-11.474-.256-11.571l.404.35c.001.098.192 9.605.255 11.206.074 1.931.002 3.887-1.02 5.1-.733.868-2.261 1.014-3.824 1.014Z"
      style={{
        fill: '#cb603b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M65.878 131.201s-.064-1.325.605-1.062c.67.262 5.232 1.066 7.846.759 2.614-.307 4.225-.889 4.762-.794.536.096.471 1.295.471 1.295s-8.05 1.473-13.684-.198Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M64.243 124.222s-1.878.574-2.473.694c-.596.119-1.073.476-1.431.834-.357.357 0 .714 0 .714s-.257.119-.158.477c.099.357.404.724.404.724l.648-.322s.713.282 1.369 0a7.33 7.33 0 0 0 .822-.467c.861-.541 1.957-1.316 1.957-1.316l-1.138-1.338Z"
      style={{
        fill: '#baaf99',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M69.717 118.993s-3.877 3.158-4.663 3.695c-.786.537-1.8 1.534-1.8 1.534l.781 1.175s2.188-.294 3.257-1.167c1.068-.872 3.885-4.503 3.885-4.503l-1.46-.734Z"
      style={{
        fill: '#275b71',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M71.953 101.641s2.902-3.178 4.497-.973c1.594 2.205 1.389 3.26 3.193 2.409 2.567-1.212 3.857.409 3.857.409s-1.952.36-2.625 1.297c-.674.936-1.971 1.958-3.526 1.266-2.093-.931-1.722-3.595-2.863-4.015-1.141-.421-1.98.126-1.98.126l-.553-.519Z"
      style={{
        fill: '#3f2b2b',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M70.407 111.831c-.135-1.204-.146-2.161-.506-3.36.162-.66 1.778-2.054 1.778-2.054l.36 1.529s.125.899 1.412 2.662c-.455.79-2.204.856-3.044 1.223Z"
      style={{
        fill: '#eeaa98',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M72.126 105.858c-.316 1.986-1.985 2.693-2.86 2.938-.512.143-1.057-.351-1.444-1.161-.429-.9-.849-2.155-.351-3.767.593-1.921 1.215-2.243 2.642-2.016 1.428.228 2.329 2.021 2.013 4.006Z"
      style={{
        fill: '#fabea9',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M72.039 107.946c-.803-1.109-.134-2.568-.215-2.927-.081-.359-.367-.755-.647-.711-.28.045-.695.652-.695.652s-.644-.311-1.262-.901c-.619-.591-1.759.017-1.794-.473-.053-.762-.879-2.016 2.306-2.639 1.455-.285 2.509.209 3.095 1.153.587.944.54 2.934.192 3.669-.464.979-.795 1.515-.98 2.177Z"
      style={{
        fill: '#433435',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M70.4 122.572c-.815 1.422-.33 3.522-.33 3.522s-3.439 6.138-4.318 7.719c-.877 1.58-.805 2.283-.446 3.424.359 1.142 3.081.703 3.081.703s4.256-3.571 5.96-5.357c1.703-1.786 4.734-3.438 3.38-7.745-1.007-3.202-5.718-3.608-7.327-2.266Z"
      style={{
        fill: '#ece5cf',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M77.929 125.2c-.555-3.88-2.263-3.187-1.987-9.085.114-2.444-.2-4.407-.893-5.007-1.598-1.381-2.058-1.617-2.058-1.617s-1.557.723-1.997 1.296c-1.092 1.423-1.986 2.239-2.746 3.855-.542 1.154.07 2.127.94 3.67.844 1.499.459 7.731.459 7.731s3.377 2.944 8.282-.843Z"
      style={{
        fill: '#44789a',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="m70.049 112.06-1.731-1.456s1.278-1.363 2.662-1.47c1.384-.106 1.397.451 1.397.451l-2.328 2.475Z"
      style={{
        fill: '#ece5cf',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M73.041 108.306s-1.912 1.433-3.215 2.75c-1.483 1.499-1.578 3.586-1.578 3.586s.717-1.358 2.146-2.675c.977-.901 3.057-2.24 3.057-2.24l-.41-1.421Z"
      style={{
        fill: '#366a88',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M70.156 150.113s-1.259-4.478-1.528-7.576c-.214-.027-.424.028-.637-.007 0 2.793 1.04 6.734 1.594 7.668.712 1.199.571-.085.571-.085Z"
      style={{
        fill: '#8e5252',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M68.299 134.954c-.441.349-.79 1.448-1.317 1.822-.527.373-1.676.461-1.676.461s1.204 3.261 2.587 5.566c.954 1.59 2.513 4.995 2.668 5.323.362.769-.171 1.81-.843 2.547-.673.738-1.382 1.317-1.752 1.844-.369.527 0 1.23 0 1.23s2.048-.236 3.152-1.114c1.105-.879 1.969-1.854 2.166-1.732.197.123 1.722-1.018 1.722-1.018s-.735-1.757-1.35-2.898c-.615-1.142-1.445-5.631-2.009-7.344-.564-1.712-1.213-2.729-1.622-3.688-.617-1.449-1.436-1.228-1.726-.999Z"
      style={{
        fill: '#44789a',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M69.111 149.619s.5 1.501 1.045 2.398c.544.896.621 1.379.621 1.379l1.408-1.011-2.029-3.406-1.045.64ZM64.738 124.838s-1.878.574-2.473.693c-.596.119-1.073.477-1.43.834-.357.358 0 .715 0 .715s-.258.119-.159.477c.099.357.269.539.269.539l.675-.431s.821.576 1.478.293c.656-.282 2.779-1.783 2.779-1.783l-1.139-1.337Z"
      style={{
        fill: '#ece5cf',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
    <path
      d="M74.01 110.984c-1.606-.554-2.215 1.837-2.45 2.415-.508 1.246-1.404 5.379-1.993 6.331-2.275 2.077-5.532 5.128-5.532 5.128l1.496 2.225s2.951-1.913 4.14-2.595c.609-.349 2.518-1.582 3.345-2.934 1.115-1.823 2.06-5.371 2.475-6.912.412-1.534-.073-3.172-1.481-3.658Z"
      style={{
        fill: '#366a88',
        fillRule: 'nonzero',
      }}
      transform="matrix(-17.9989 0 0 17.9989 2372.83 -1660.65)"
    />
  </svg>
);

export const Trophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 2900 2000"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M343.183 484.039c-1.597-.456-39.844-11.792-102.617-65.216-64.871-55.209-66.949-146.38-67.006-150.234a11.786 11.786 0 0 1 11.647-11.958l111.313-1.31.276 23.57-98.74 1.162c2.798 24.893 13.934 83.499 57.787 120.82 58.264 49.586 93.524 60.414 93.873 60.518l-6.533 22.648ZM627.287 484.039l-6.476-22.664c.293-.088 35.552-10.916 93.817-60.502 43.848-37.319 54.986-95.916 57.787-120.82l-98.741-1.162.277-23.57 111.313 1.31a11.78 11.78 0 0 1 11.646 11.958c-.056 3.854-2.134 95.025-67.005 150.234-62.773 53.424-101.02 64.76-102.618 65.216Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M291.42 256.629s-14.405-5.238-14.405-19.643c0-14.406-2.62-20.953 10.476-34.049s112.186-30.993 192.07-30.993c79.883 0 189.014 21.826 202.109 33.612 13.095 11.786 7.857 43.216 5.238 48.454-2.619 5.238-13.095 3.929-13.095 3.929l-382.393-1.31Z"
      style={{
        fill: '#ffcf3f',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M478.687 216.033c-49.31 0-153.219 6.547-191.196 34.048 0 0 0 141.433 39.287 213.459 39.287 72.026 108.694 130.957 108.694 130.957h94.288s69.407-58.931 108.694-130.957c39.287-72.026 39.287-213.459 39.287-213.459-41.906-27.501-149.743-34.048-199.054-34.048Z"
      style={{
        fill: '#ffd733',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M478.687 216.033c-49.31 0-153.219 6.547-191.196 34.048 0 0 0 141.433 39.287 213.459 18.612 34.123 43.978 65.293 65.528 88.663 15.075 6.4 32.407 10.431 52.333 10.864 120.48 2.62 151.909-99.527 165.005-136.195 20.633-57.771 32.374-148.482 36.985-190.578-50.623-16.007-128.241-20.261-167.942-20.261Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M617.501 771.288c-11.787-35.359-36.668-30.12-36.668-30.12s-.042-11.368-17.459-12.997c-10.79-3.32-38.598-14.001-48.02-38.076-11.786-30.12-6.547-68.097-6.547-68.097l-26.191-3.417-26.191 3.417s5.238 37.977-6.548 68.097c-9.421 24.075-37.229 34.756-48.019 38.076-17.417 1.629-17.459 12.997-17.459 12.997s-24.882-5.239-36.668 30.12h269.77Z"
      style={{
        fill: '#ffce45',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M384.399 741.168a1156.671 1156.671 0 0 1 49.109-2.126c16.369-.436 32.738-.552 49.108-.607 16.37.048 32.738.165 49.109.599 16.369.358 32.739 1.071 49.109 2.134a1143.714 1143.714 0 0 1-49.109 2.134c-16.371.434-32.739.551-49.109.599-16.37-.055-32.739-.171-49.108-.608a1158.33 1158.33 0 0 1-49.109-2.125ZM529.76 612.831h-92.978c-5.786 0-10.477-4.691-10.477-10.477 0-5.786 4.691-10.477 10.477-10.477h92.978c5.786 0 10.477 4.691 10.477 10.477 0 5.786-4.691 10.477-10.477 10.477Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M524.523 626.055H442.02a9.295 9.295 0 0 1-9.296-9.296 9.295 9.295 0 0 1 9.296-9.296h82.503a9.295 9.295 0 0 1 9.295 9.296 9.295 9.295 0 0 1-9.295 9.296Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M344.306 769.978h285.787c9.681 0 17.528 7.848 17.528 17.528v120.782c0 9.68-7.847 17.528-17.528 17.528H344.306c-9.68 0-17.528-7.848-17.528-17.528V787.506c0-9.68 7.848-17.528 17.528-17.528Z"
      style={{
        fill: '#6c554f',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M392.518 796.956h189.363a8.119 8.119 0 0 1 8.119 8.118v66.002a8.12 8.12 0 0 1-8.119 8.12H392.518a8.12 8.12 0 0 1-8.119-8.12v-66.002a8.119 8.119 0 0 1 8.119-8.118Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M418.621 796.956h65.131c1.543 0 2.793 3.635 2.793 8.118v66.002c0 4.485-1.25 8.12-2.793 8.12h-65.131c-1.542 0-2.792-3.635-2.792-8.12v-66.002c0-4.483 1.25-8.118 2.792-8.118Z"
      style={{
        fill: '#ffd733',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M435.472 594.497c7.857-.564 15.714-.828 23.572-1.047 7.857-.25 15.715-.285 23.572-.32 7.857.031 15.715.067 23.572.315l11.786.382c3.929.227 7.858.36 11.786.67-3.928.31-7.857.443-11.786.67l-11.786.381c-7.857.248-15.715.284-23.572.315-7.857-.035-15.715-.069-23.572-.32-7.858-.218-15.715-.482-23.572-1.046ZM435.908 610.212c8.003-.564 16.006-.829 24.009-1.047 8.003-.25 16.006-.285 24.009-.32 8.003.031 16.005.067 24.008.315l12.005.382c4.001.226 8.002.359 12.004.67-4.002.309-8.003.442-12.004.67l-12.005.381c-8.003.248-16.005.284-24.008.315-8.003-.035-16.006-.07-24.009-.32-8.003-.219-16.006-.483-24.009-1.046ZM457.338 627.672c8.426-1.075 16.852-1.322 25.278-1.366 8.427.038 16.852.285 25.278 1.366-8.426 1.082-16.851 1.328-25.278 1.367-8.426-.044-16.852-.292-25.278-1.367Z"
      style={{
        fill: '#ffe266',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M353.406 758.629a1999.266 1999.266 0 0 1 64.605-2.126c21.535-.436 43.07-.552 64.605-.607 21.535.048 43.07.164 64.605.599 21.535.357 43.07 1.071 64.605 2.134a1973.99 1973.99 0 0 1-64.605 2.133c-21.535.435-43.07.551-64.605.599-21.535-.054-43.07-.171-64.605-.607a2002.135 2002.135 0 0 1-64.605-2.125Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M349.48 227.659c.193-15.845.57-30.67 1.008-43.731 18.69-3.379 40.018-6.347 62.018-8.487-1.176 12.41-2.388 27.061-3.494 43.884-19.859 1.812-40.503 4.493-59.532 8.334Z"
      style={{
        fill: '#ffe69a',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M428.924 549.972s-41.906-44.525-65.478-133.576c-12.62-47.674-14.729-126.127-13.966-188.737 19.029-3.841 39.673-6.522 59.532-8.334-1.569 23.859-2.925 52.083-3.66 84.449-2.619 115.241 23.572 246.198 23.572 246.198Z"
      style={{
        fill: '#fff0ae',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M677.741 250.081c-14.506-8.302-30.59-13.052-46.74-16.744-16.183-3.705-32.611-6.295-49.1-8.314-16.502-1.931-33.057-3.46-49.649-4.446-16.586-1.033-33.206-1.711-49.805-1.781-16.591-.161-33.212.408-49.802 1.333-16.599.855-33.158 2.335-49.658 4.244-16.485 2.003-32.927 4.501-49.093 8.255-16.122 3.74-32.223 8.584-46.403 17.453 6.79-4.81 14.125-9.025 21.957-11.997 1.941-.782 3.864-1.629 5.826-2.359l5.947-2.015c3.947-1.425 7.976-2.557 12.011-3.679 16.161-4.413 32.645-7.505 49.2-9.889 33.135-4.708 66.564-6.85 100.048-6.811 33.465.379 66.867 2.718 99.987 7.479 16.551 2.398 33.016 5.581 49.183 9.943 4.06 1.012 8.038 2.344 12.024 3.623 4.015 1.182 7.911 2.74 11.811 4.257 3.952 1.379 7.679 3.333 11.461 5.105 1.916.841 3.697 1.946 5.468 3.063l5.327 3.28Z"
      style={{
        fill: '#fcab28',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="m397.178 278.717 12.229-21.431 11.447 21.859 21.432 12.23-21.859 11.447-12.23 21.432-11.447-21.859-21.432-12.23 21.86-11.448Z"
      style={{
        fill: '#fff',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M406.422 925.816h-48.533l34.107-46.637c.173.011.347.017.522.017h50.165l-36.261 46.62Zm100.225-128.86H452.13l19.73-26.978h55.77l-20.983 26.978Z"
      style={{
        fill: '#9f908c',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M418.621 879.196h-26.103c-.175 0-.349-.006-.522-.017l23.833-32.587v24.484c0 4.485 1.25 8.12 2.792 8.12Zm67.924-56.395v-17.727c0-4.483-1.25-8.118-2.793-8.118H452.13h54.517l-20.102 25.845Z"
      style={{
        fill: '#fdc873',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M442.683 879.196h-24.062c-1.542 0-2.792-3.635-2.792-8.12v-24.484l36.301-49.636h31.622c1.543 0 2.793 3.635 2.793 8.118v17.727l-43.862 56.395Z"
      style={{
        fill: '#ffe57a',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M477.705 925.816h-24.037l36.336-46.62h23.665l-35.964 46.62Zm99.407-128.86h-23.009l21.026-26.978h22.794l-20.811 26.978Z"
      style={{
        fill: '#9f908c',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
    <path
      d="M513.669 879.196h-23.665l64.099-82.24h23.009l-63.443 82.24Z"
      style={{
        fill: '#fdc873',
        fillRule: 'nonzero',
      }}
      transform="translate(535.155 -40.493) scale(1.91466)"
    />
  </svg>
);
