import { Box, Button, Divider, Flex, Progress, Text } from '@chakra-ui/react';
import { Image } from 'components/Image';
import { CardProps } from 'static/cards';
import { theme } from 'styles';

export const Card = (props: CardProps) => {
  const { image, name, percentFunded, investors } = props;

  return (
    <Box
      borderRadius="6px"
      boxShadow="0px 0px 20px 0px rgba(0,0,0,0.1)"
      maxW="280px"
      w="100%"
    >
      <Box
        position="relative"
        height="200px"
        __css={{ img: { borderRadius: '6px 6px 0px 0px' } }}
      >
        <Image src={image.url} objectFit="cover" />
      </Box>
      <Box p="15px" bg={theme.colors.white} borderRadius="0px 0px 6px 6px">
        <Flex justifyContent="space-between">
          <Flex flexDir="column">
            <Text fontSize="8px" mb="0">
              Name
            </Text>
            <Text fontWeight="600" mb="0">
              {name}
            </Text>
          </Flex>
          <Flex flexDir="column">
            <Text fontSize="8px" mb="0">
              Remaining Investment
            </Text>
            <Box
              maxW="80px"
              w="100%"
              h="18px"
              borderRadius={20}
              bg={theme.colors.geyser}
              m="auto"
              mr="0"
            />
          </Flex>
        </Flex>

        <Flex
          justifyContent="space-evenly"
          alignItems="center"
          mt="20px"
          mb="20px"
        >
          <Flex flexDir="column">
            <Flex justifyContent="center" alignItems="center" fontSize="12px">
              <Box
                w="30px"
                h="18px"
                borderRadius={20}
                bg={theme.colors.geyser}
                mr="3px"
              />
              %
            </Flex>
            <Text fontSize="10px" mb="0" mt="5px" textAlign="center">
              Est. Return
            </Text>
          </Flex>
          <Divider orientation="vertical" h="40px" m="0px" />
          <Flex flexDir="column">
            <Flex justifyContent="center" alignItems="center" fontSize="12px">
              <Box
                w="30px"
                h="18px"
                borderRadius={20}
                bg={theme.colors.geyser}
                mr="3px"
              />
              %
            </Flex>
            <Text fontSize="10px" mb="0" mt="5px" textAlign="center">
              LTV
            </Text>
          </Flex>
          <Divider orientation="vertical" h="40px" m="0px" />
          <Flex flexDir="column">
            <Flex justifyContent="center" alignItems="center" fontSize="12px">
              <Box
                w="30px"
                h="18px"
                borderRadius={20}
                bg={theme.colors.geyser}
                mr="3px"
              />
              Mo.
            </Flex>
            <Text fontSize="10px" mb="0" mt="5px" textAlign="center">
              Est. Sale
            </Text>
          </Flex>
        </Flex>
        <Progress value={percentFunded} colorScheme="teal" height="7px" />
        <Text fontSize="10px" mt="5px" textAlign="right" mb="0">
          {`${percentFunded}% funded by ${investors} investors`}
        </Text>
        <Button variant="primary" w="100%" mt="15px">
          Invest
        </Button>
      </Box>
    </Box>
  );
};
