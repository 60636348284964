import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import { theme } from 'styles';
import parse from 'react-html-parser';

interface BenefitProps {
  heading?: string | null;
  snippet?: string | null;
  index: number;
}

export const Benefit = ({ heading, snippet, index }: BenefitProps) => {
  return (
    <AccordionItem mb="20px" border="none" bg="white">
      <AccordionButton
        border={`3px solid ${theme.colors.primary}`}
        _focus={{ boxShadow: 'none' }}
        _hover={{ bg: '#fff' }}
        bg={theme.colors.primary}
        borderRadius="2px"
      >
        <Box flex="1" textAlign="left">
          <Text mb="0" fontWeight={500}>{`0${index + 1} ${heading}`}</Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        border={`3px solid ${theme.colors.primary}`}
        borderTop="none"
      >
        <Text mb="0">{parse(snippet || '')}</Text>
      </AccordionPanel>
    </AccordionItem>
  );
};
