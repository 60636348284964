import { Box, Text } from '@chakra-ui/react';
import { Card } from 'components/Card';
import { motion } from 'framer-motion';
import { cardData } from 'static/cards';

export const Tinder = () => {
  return (
    <>
      {cardData.map((item, idx) => {
        const variants = {
          0: {
            x: [
              0, 0, 0, -150, -150, -150, -150, -150, -150, -150, -150, -150,
              -150, -150, -150,
            ],
            y: [0, 0, 0, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
            rotate: [
              0, 0, 0, -12, -12, -12, -12, -12, -12, -12, -12, -12, -12, -12,
              -12,
            ],
            opacity: [1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          1: {
            x: [
              0, 0, 0, 0, 0, 0, 0, -150, -150, -150, -150, -150, -150, -150,
              -150,
            ],
            y: [0, 0, 0, 0, 0, 0, 0, 50, 50, 50, 50, 50, 50, 50, 50],
            rotate: [
              0, 0, 0, 0, 0, 0, 0, -12, -12, -12, -12, -12, -12, -12, -12,
            ],
            opacity: [1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          2: {
            x: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 150, 150, 150, 150],
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 245, 245, 245, 245],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 12, 12, 12],
            opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
          },
        };

        const textVariants = {
          0: {
            y: [
              0, 0, 0, -60, -60, -60, -60, -60, -60, -60, -60, -60, -60, -60,
              -60,
            ],
            opacity: [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          1: {
            y: [0, 0, 0, 0, 0, 0, 0, -60, -60, -60, -60, -60, -60, -60, -60],
            opacity: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
          },
          2: {
            y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -60, -60, -60, -60],
            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
          },
        };

        return (
          <Box
            key={item.name}
            pos="absolute"
            m="auto"
            w="280px"
            zIndex={cardData.indexOf(cardData[cardData.length - 1 - idx])}
          >
            <motion.div
              animate={variants[idx as keyof typeof variants]}
              variants={variants}
              transition={{
                duration: 15,
                ease: 'easeInOut',
                repeat: Infinity,
                repeatType: 'loop',
              }}
            >
              <Card {...item} />
            </motion.div>
            <motion.div
              animate={textVariants[idx as keyof typeof textVariants]}
              variants={textVariants}
              transition={{
                duration: 15,
                ease: 'easeIn',
                repeat: Infinity,
                repeatType: 'loop',
              }}
              style={{
                position: 'absolute',
                inset: 0,
                margin: 'auto',
                display: 'flex',
                zIndex: 99,
              }}
            >
              <Text
                m="auto"
                fontWeight={900}
                fontSize="32px"
                color={idx !== 2 ? '#D6002F' : '#319795'}
                padding="6px 12px"
                border={`4px solid ${idx !== 2 ? '#D6002F' : '#319795'}`}
                borderRadius="6px"
              >
                {item.match}
              </Text>
            </motion.div>
            {item.match === 'MATCH' && (
              <>
                <motion.div
                  animate={{
                    y: [
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -135, -135, -135, -135,
                    ],
                    opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
                  }}
                  transition={{
                    duration: 15,
                    ease: 'easeIn',
                    repeat: Infinity,
                    repeatType: 'loop',
                  }}
                  style={{
                    position: 'absolute',
                    inset: 0,
                    margin: 'auto',
                    display: 'flex',
                    zIndex: 99,
                    translateX: '-70px',
                  }}
                >
                  <Text m="auto" fontSize="48px">
                    🎊
                  </Text>
                </motion.div>
                <motion.div
                  animate={{
                    y: [
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -135, -135, -135, -135,
                    ],
                    opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
                  }}
                  transition={{
                    duration: 15,
                    ease: 'easeIn',
                    repeat: Infinity,
                    repeatType: 'loop',
                  }}
                  style={{
                    position: 'absolute',
                    inset: 0,
                    margin: 'auto',
                    display: 'flex',
                    zIndex: 99,
                  }}
                >
                  <Text m="auto" fontSize="48px">
                    🥳
                  </Text>
                </motion.div>
                <motion.div
                  animate={{
                    y: [
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -135, -135, -135, -135,
                    ],
                    opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
                  }}
                  transition={{
                    duration: 15,
                    ease: 'easeIn',
                    repeat: Infinity,
                    repeatType: 'loop',
                  }}
                  style={{
                    position: 'absolute',
                    inset: 0,
                    margin: 'auto',
                    display: 'flex',
                    zIndex: 99,
                    translateX: '70px',
                  }}
                >
                  <Text m="auto" fontSize="48px">
                    🎉
                  </Text>
                </motion.div>
              </>
            )}
          </Box>
        );
      })}
    </>
  );
};
