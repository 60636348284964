import { Box, Flex, Text } from '@chakra-ui/react';
import { Container } from 'components/Layout/Container';
import parse from 'react-html-parser';
import { Image } from 'components/Image';
import { theme } from 'styles';

export interface ThreeBenefitsProps {
  title: string;
  content: {
    icon: { url: string; alt?: string };
    heading: string;
    snippet: string;
  }[];
}

export const ThreeReasons = ({ title, content }: ThreeBenefitsProps) => {
  return (
    <Box>
      <Container pt="80px" pb="120px" textAlign="center">
        <Box
          bgImage="radial-gradient(circle, #D3D4D4, #D3D4D4 1px, transparent 1px)"
          bgSize="8px 8px"
          padding="1.5rem"
          w="fit-content"
          m="auto"
        >
          <Text
            as="h3"
            maxW="750px"
            m="auto"
            bg={theme.colors.white}
            fontSize={{ base: '28px', md: '40px' }}
          >
            {title}
          </Text>
        </Box>
        <Flex
          gap={{ base: '40px', md: '25px', lg: '45px', xl: '60px' }}
          mt="70px"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'revert' }}
        >
          {content.map((item) => {
            const { icon, heading, snippet } = item;

            return (
              <Flex
                flexDir="column"
                key={heading}
                flex={1}
                alignItems="center"
                border="1px solid black"
                borderRadius="4px"
                padding={{ base: '30px', lg: '2.5rem 1.25rem 2rem' }}
                boxShadow="5px 5px 10px 0px rgba(0,0,0,0.2)"
                maxW="400px"
              >
                <Box width="80px" height="80px" position="relative" mb="35px">
                  <Image src={icon.url} />
                </Box>
                <Text fontWeight={600} fontSize="22px">
                  {heading}
                </Text>
                <Text mb="0px">{parse(snippet)}</Text>
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
};
