import {
  Flex,
  Text,
  Button,
  Accordion,
  // ExpandedIndex,
  Box,
} from '@chakra-ui/react';
import { Container } from 'components/Layout/Container';
// import { Image } from 'components/Image';
import { useRouter } from 'next/router';
// import { useState } from 'react';
import { HomepageHowItWorksSection } from 'types';
import { theme } from 'styles';
import { Tinder } from 'components/Animations/Tinder';
import { Benefit } from './Benefit';

export const Benefits = ({
  howItWorksRepeater,
  heading,
  ctaObject,
}: HomepageHowItWorksSection) => {
  // const [activeIndex, setActiveIndex] = useState<ExpandedIndex>(0);

  const router = useRouter();

  return (
    <Box bg={theme.colors.accent.beige}>
      <Container pt="80px" pb="60px">
        <Text
          align="center"
          mb="60px"
          as="h2"
          fontSize={{ base: '32px', md: '45px' }}
        >
          {heading}
        </Text>
        <Flex
          justifyContent="space-between"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            position="relative"
            order={{ base: 1, md: 0 }}
            display={{ base: 'none', md: 'flex' }}
          >
            <Tinder />
          </Flex>
          {/* {howItWorksRepeater?.map((item, index) => {
            if (index !== activeIndex) return null;

            if (index === 0)
              return (
                <Flex
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  key={item?.image?._key}
                >
                  <Tinder />
                </Flex>
              );

            return (
              <Box
                width="500px"
                height="500px"
                position="relative"
                key={item?.image?._key}
              >
                <Image src={item?.image?.asset?.url} />
              </Box>
            );
          })} */}
          <Accordion
            maxW={{ base: '100%', md: '475px', lg: '650px' }}
            w="100%"
            defaultIndex={0}
            // onChange={(idx) => setActiveIndex(idx)}
          >
            {howItWorksRepeater?.map((benefit, idx) => (
              <Benefit key={benefit?.heading} {...benefit} index={idx} />
            ))}
          </Accordion>
        </Flex>
        {ctaObject?.link && (
          <Button
            mt="30px"
            mx="auto"
            as="a"
            display="flex"
            w="fit-content"
            variant="primary"
            color="#fff"
            href={`/${ctaObject?.link}`}
            onClick={() => router.push(`/${ctaObject.link}`)}
          >
            {ctaObject.text}
          </Button>
        )}
      </Container>
    </Box>
  );
};
