import { DescriptionProps } from 'components/Description';
import { ThreeBenefitsProps } from 'components/_pages/Home/ThreeReasons';

export const description: DescriptionProps = {
  content: [
    {
      title: 'Flexible Investment Options',
      description: `Lucrative investment opportunities are no longer available for just the ultra-wealthy. With Equine Street’s share-based investment platform, you have the opportunity to invest partially or completely in our high-demand showhorses with total flexibility.
  `,
      image: { url: '/images/home/cards.png' },
    },
    {
      title: 'Passive Profits',
      description: `
      Equine Street offers you a unique opportunity to diversify, all while not having to manage your investment. We take care of the horses medical care, insurance, training, and sale to make this a truly passive investment.
      `,
      image: { url: '/images/home/account-chart.jpg' },
    },
    {
      title: 'Asset-backed Security of Funds',
      description: `
      Equine Street is uniquely set up to protect your investments. 
      <ul>
      <li>Proprietary partnerships allow us to acquire horses at wholesale prices, providing built-in profit.</li>
      <li>Each horse is secured with mortality and injury insurance protection.</li>
      <li>Value increased through training, which is linear and predictable.</li>
      </ul>
      `,
      image: { url: '/images/home/investor.jpg' },
    },
  ],
};

export const threeReasons: ThreeBenefitsProps = {
  title: 'Unlock An Asset Class Previously Only Available To The Ultra-Wealthy',
  content: [
    {
      heading: 'Low Minimum Investment',
      snippet:
        'You can invest with as little as $500 and own part of one or multiple horses.  That makes this opportunity finally available to almost anyone.',
      icon: { url: '/images/home/icons/coins.png' },
    },
    {
      heading: 'Passive Income',
      snippet:
        'This is predictable and passive income. You invest in through the Equine Street platform and our team of professionals handle every part of the process for you. Once the horse has been sold, your profits are distributed.',
      icon: { url: '/images/home/icons/growth.png' },
    },
    {
      heading: 'Diversification',
      snippet:
        'Until now, investing in show horses was not easily accessible to the every day investor. With Equine Street, you unlock a new asset class all while getting the ultimate flexibility to invest how and when you want.',
      icon: { url: '/images/home/icons/worldwide.png' },
    },
  ],
};

export const whyHorses = [
  {
    title: 'Show Horses Have Predictable Value Appreciation',
    description:
      'Unlike racehorses, show horses appreciate in value as they move up in training levels.  This doesn’t require them to win any races, just be trained, and show in competition at those levels.  As they learn new moves and gain levels, they increase in value.   Racehorses earn money by winning races, which is prone to luck and injury.  We like to invest, NOT gamble.',
    image: { url: '/images/home/investor.jpg' },
  },
  {
    title: 'Shorter Investment Timeline',
    image: { url: '/images/home/money-tree.jpg' },
    description:
      'We acquire show horses with the intention of selling them in as soon as 2 months to 1 year for a substantial profit.  Each horse has a game plan, which gives you educated estimates on timing and returns.',
  },
  {
    title: 'Huge ROI Potential on Show Horses',
    image: { url: '/images/home/banker.jpg' },
    description:
      'Depending on the horse, training level achieved, and skills learned, we’ve experienced as much as 2x to 4x value increase in as short as a few months to a year.',
  },
  {
    title: 'High Demand for Show Horses with Big Pockets',
    image: { url: '/images/home/money-tree.jpg' },
    description:
      'There is and has been for many years a strong market for show horses that is growing.  Most riders want to the ease of purchasing a horse that’s already trained for them to the level they want. That’s where we come in.  We provide horses that are trained to higher levels for them.  Typically, these buyers have large budgets and as a result the opportunity is thriving.',
  },
  // {
  //   title: 'Show Horses are Less Prone to Injury than Racehorses',
  //   image: { url: '/images/home/banker.jpg' },
  //   description:
  //     'They are not sprinting, not racing, usually making smooth and efficient movements.  And as a result, they have less possible injuries, making them a far safer investment.',
  // },
];
