import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Image } from 'components/Image';
import { Container } from 'components/Layout/Container';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import parse from 'react-html-parser';
import { useHomepageQuery } from 'types';

export const Hero: FunctionComponent = () => {
  const { data } = useHomepageQuery();
  const hero = data?.homepage?.hero;

  return (
    <Box>
      <Container
        pt={{ base: '60px', md: '85px' }}
        pb={{ base: '0px', md: '120px' }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pos="relative"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box
            flex={1}
            mr={{ base: '0px', md: '30px' }}
            mb={{ base: '40px', md: '0px' }}
            maxW={{ md: '385px', lg: '450px', xl: '630px' }}
            w="100%"
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Heading
              as="h1"
              fontSize={{ base: '32px', lg: '50px', xl: '60px' }}
              mb="15px"
            >
              {hero?.heading}
            </Heading>
            <Text
              fontSize={{ base: '18px', xl: '20px' }}
              mb={hero?.ctaObject ? '30px' : undefined}
            >
              {parse(hero?.snippet || '')}
            </Text>
            {hero?.ctaObject?.link && (
              <Link href={hero?.ctaObject?.link} passHref>
                <Button variant="primary" as="a">
                  {hero?.ctaObject?.text}
                </Button>
              </Link>
            )}
          </Box>
          <Box
            pos={{ base: 'relative', md: 'absolute' }}
            width={{
              base: '315px',
              sm: '500px',
              md: '400px',
              lg: '550px',
              xl: '650px',
            }}
            height={{
              base: '315px',
              sm: '500px',
              md: '400px',
              lg: '550px',
              xl: '650px',
            }}
            right={{ base: 'auto', md: '0px', xl: '-100px' }}
            top={{ base: 'auto', md: '0' }}
          >
            {hero?.image?.asset?.url && (
              <Image src={hero?.image?.asset?.url} objectPosition="top" />
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
