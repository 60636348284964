import { Box, Flex, Text } from '@chakra-ui/react';
import { Image } from 'components/Image';
import { Container } from 'components/Layout/Container';
import { Award, Horse, Medal, Trophy } from 'components/Svg';
import { motion } from 'framer-motion';
import parse from 'react-html-parser';

export interface DescriptionProps {
  content: {
    title: string;
    description: string;
    image: { url: string; alt?: string };
    button?: { text: string; url: string };
  }[];
}

export const Description = ({ content }: DescriptionProps) => {
  return (
    <Box pt="30px" pb={{ base: '40px', md: '120px' }} overflow="hidden">
      {content.map((item, index) => {
        const { title, description, image, button } = item;

        const isOdd = index % 2 === 0;

        const transition = {
          duration: 3,
          repeat: Infinity,
          repeatType: 'loop' as 'loop',
        };

        if (index === 2) {
          return (
            <Container
              pt={{ base: index > 0 ? '120px' : '60px', md: '60px' }}
              key={title}
            >
              <Flex
                flexDir={{
                  base: 'column',
                  md: isOdd ? 'row' : 'row-reverse',
                }}
                justifyContent="space-between"
                alignItems="center"
                pos="relative"
              >
                <Box
                  pos="absolute"
                  zIndex="-1"
                  left="-420px"
                  top="0px"
                  height="650px"
                  width="650px"
                >
                  <Image src="/images/home/background/circle-shapes.png" />
                </Box>

                <Box
                  flex={1}
                  maxW="525px"
                  order={{ base: 1, md: 0 }}
                  m={{ md: !isOdd ? '0 0 0 20px' : '0 20px 0 0', lg: '0' }}
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  <Text as="h3" fontSize={{ base: '28px', md: '42px' }}>
                    {title}
                  </Text>
                  <Text
                    as="div"
                    sx={{
                      ul: {
                        paddingInlineStart: '1rem',
                        fontSize: '16px',
                        mt: '30px',
                        textAlign: 'left',
                      },
                    }}
                  >
                    {parse(description)}
                  </Text>
                  {button && <div>button</div>}
                </Box>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={{ base: '100%', md: '500px', xl: '600px' }}
                  height={{ base: '310px', md: '400px', xl: '500px' }}
                  position="relative"
                  order={{ base: 0, md: 1 }}
                  mb={{ base: '30px', md: '0px' }}
                >
                  <Box
                    width={{ base: '310px', md: '400px' }}
                    sx={{
                      svg: {
                        mr: '40px',
                        mb: { base: '-60px', md: '0px' },
                      },
                    }}
                  >
                    <Horse />
                  </Box>
                  <Box
                    pos="absolute"
                    zIndex={99}
                    top={{ base: '25px', md: '-25px', xl: '25px' }}
                    right={{
                      base: '215px',
                      md: '130px',
                      xl: '170px',
                    }}
                    h={{ base: '75px', md: '90px' }}
                    w={{ base: '75px', md: '90px' }}
                  >
                    <motion.div
                      animate={{
                        opacity: [0, 0, 0, 1, 1, 1],
                        y: ['20px', '20px', '20px', '0px', '0px', '0px'],
                      }}
                      transition={transition}
                    >
                      <Award />
                    </motion.div>
                  </Box>
                  <Box
                    pos="absolute"
                    zIndex={99}
                    top={{ base: '-40px', md: '-70px', xl: '-40px' }}
                    h={{ base: '110px', md: '130px' }}
                    w={{ base: '110px', md: '130px' }}
                  >
                    <motion.div
                      animate={{
                        opacity: [0, 0, 1, 1, 1, 1],
                        y: ['20px', '20px', '0px', '0px', '0px', '0px'],
                      }}
                      transition={transition}
                    >
                      <Trophy />
                    </motion.div>
                  </Box>
                  <Box
                    pos="absolute"
                    zIndex={99}
                    top={{ base: '25px', md: '-25px', xl: '25px' }}
                    left={{ base: '210px', md: '130px', xl: '170px' }}
                    h={{ base: '90px', md: '100px' }}
                    w={{ base: '90px', md: '100px' }}
                  >
                    <motion.div
                      animate={{
                        opacity: [0, 1, 1, 1, 1, 1],
                        y: ['20px', '0px', '0px', '0px', '0px', '0px'],
                      }}
                      transition={transition}
                    >
                      <Medal />
                    </motion.div>
                  </Box>
                </Flex>
              </Flex>
            </Container>
          );
        }

        return (
          <Container
            pt={{ base: index > 0 ? '120px' : '60px', md: '60px' }}
            key={title}
          >
            <Flex
              flexDir={{
                base: 'column',
                md: isOdd ? 'row' : 'row-reverse',
              }}
              justifyContent="space-between"
              alignItems="center"
              pos="relative"
            >
              {index === 0 && (
                <Box
                  pos="absolute"
                  right={{ base: '-270px', md: '0' }}
                  top={{ base: '600px', md: 'initial' }}
                  height={{ base: '600px', md: '600px' }}
                  width={{ base: '600px', md: '600px' }}
                  zIndex="-1"
                >
                  <Image src="/images/home/background/circle-shapes.png" />
                </Box>
              )}
              <Box
                flex={1}
                maxW="525px"
                order={{ base: 1, md: 0 }}
                textAlign={{ base: 'center', md: 'left' }}
                m={{ md: !isOdd ? '0 0 0 40px' : '0 40px 0 0', lg: '0' }}
              >
                <Text as="h3" fontSize={{ base: '28px', md: '42px' }}>
                  {title}
                </Text>
                <Text
                  as="div"
                  sx={{
                    ul: {
                      paddingInlineStart: '1rem',
                      fontSize: '16px',
                      mt: '30px',
                    },
                  }}
                >
                  {parse(description)}
                </Text>
                {button && <div>button</div>}
              </Box>
              <Box
                width={{ base: '100%', md: '500px', xl: '600px' }}
                height={{ base: '310px', md: '400px', xl: '500px' }}
                position="relative"
                order={{ base: 0, md: 1 }}
                mb={{ base: '30px', md: '0px' }}
              >
                <Image src={image.url} />
              </Box>
            </Flex>
          </Container>
        );
      })}
    </Box>
  );
};
