import { Description } from 'components/Description';
import { Benefits } from 'components/_pages/Home/Benefits';
import { Hero } from 'components/_pages/Home/Hero';
import { ThreeReasons } from 'components/_pages/Home/ThreeReasons';
// import { WhyHorses } from 'components/_pages/Home/WhyHorses';
import { addApolloState, initializeApollo } from 'lib/clients/apolloClient';
import { GLOBAL, HOMEPAGE } from 'queries';
import { description, threeReasons } from 'static/home';
import { useHomepageQuery } from 'types';

const Homepage = () => {
  const { data = {} } = useHomepageQuery();
  const { homepage } = data;

  const howItWorks = homepage?.howItWorksSection;

  return (
    <>
      <Hero />
      <Benefits {...howItWorks} />
      <Description content={description.content} />
      {/* <WhyHorses /> */}
      <ThreeReasons title={threeReasons.title} content={threeReasons.content} />
    </>
  );
};

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  await Promise.all([
    apolloClient.query({
      query: GLOBAL,
    }),
    apolloClient.query({
      query: HOMEPAGE,
    }),
  ]);

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 60 * 5,
  });
}

export default Homepage;
